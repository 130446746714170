@import "./assets/fonts/fonts.css";

// :root {
//   --main: #0062b3;
//   --alternative-main: #005bb9;
//   --main-light: #80b1d9;
//   --main-alternative-light: #4d91ca;
//   --main-lighter: #ccdfef;
//   --main-alternative-lighter: #f2f6f9;
//   --black: #242424;
//   --alternative-dark: #191919;
//   --input-black: #4c4c4c;
//   --alternative-black: #3b3b3b;
//   --light-gray: #fff8f5;
//   --secondary: #ff7e40;
//   --alternative-secondary: #ff7610;
//   --alternative-secondary-light: #ff9c36;
//   --secondary-light: #ffbca4;
//   --secondary-lighter: #ffe5d8;
//   --yellow-light: #ffe396;
//   --dark: #666666;
//   --dark-light: #7f7f7f;
//   --gray: #a4a3a3;
//   --alternative-gray: #929292;
//   --gray-light: #a6a6a6;
//   --gray-dark: #484747;
//   --light: #808080;
//   --danger: #ee1200;
//   --danger-light: #f8b2ac;

//   --green: #00b329;
//   --light-purple: #f4f2f9;

//   --secondary-font: "Proxima Nova", serif;
//   --main-font: "Open Sans", sans-serif;

//   --font-xxs: 10px;
//   --font-xs: 12px;
//   --font-sm: 14px;
//   --font-md: 16px;
//   --font-lg: 18px;
//   --font-xl: 24px;
//   --font-xxl: 32px;

//   // ------------------------------------------------------------------

//   --main-dark: #2680eb;
//   --transparent-main-light: #1191e23d;

//   --secondary-dark: #e8c61c;
//   --transparent-secondary-dark: #ffc73d52;

//   --purple: #651fff;
//   --lighter-purple: #e0d2ff;
//   --lighter-alternative-purple: #d5cce7;
//   --alternative-dark-purple: #6d4dae;
//   --dark-purple: #410cb3;
//   --transparent-purple: rgba(151, 128, 197, 1);

//   --orange: #ff911f;
//   --orange-light: #ffbfa0;

//   --danger: #e80606;
//   --danger-dark: #950000;
//   --danger-light: #ef5151;
//   --danger-lighter: #facdcd;

//   --lighter: #e5e5e5;
//   --lightness: #f8f8f8;
//   --transparent-gray: #d6dcd97a;

//   --white: #ffffff;
//   --transparent-black: #00000014;
//   --alternative-transparent-black: rgba(0, 0, 0, 0.4);

//   // --font-xxs: 10px;
//   // --font-xl: 20px;
//   // --font-uxl: 48px;
// }

@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  line-height: inherit;
}

.ng-value-container {
  .ng-input {
    & > input {
      font-family: var(--main-font);
      font-size: var(--font-sm);
      color: var(--input-black) !important;
    }
  }

  .ng-value-label {
    font-family: var(--main-font);
    font-size: var(--font-sm);
    color: var(--black) !important;
  }
  .ng-placeholder {
    font-family: var(--main-font);
    font-size: var(--font-sm);
    color: var(--main-light) !important;
  }
}

.button-sticky {
  z-index: 3;
}

.invalid .ng-placeholder {
  color: var(--danger-lighter) !important;
}

.ng-select-container {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;

  .ng-arrow {
    border-color: var(--main) transparent transparent !important;
    transition: all ease 0.3s;
  }

  .ng-arrow-wrapper {
    width: 12px;
    padding: 0;
  }
}

.hide-arrow {
  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 8px;
  border-color: var(--main-lighter);
  border-radius: 6px;
  width: calc(100% + 12px);
  left: -6px;
}

.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(:last-child) {
  border-bottom: 1px solid var(--main-lighter);
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  font-weight: 400;
}

.ng-select div,
.ng-select input,
.ng-select span {
  font-size: var(--font-sm) !important;
  color: var(--input-black) !important;
  font-family: var(--main-font) !important;
}

.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-width: 5px 5px 2.5px !important;
  transform: rotate(-180deg) !important;
  top: -2px !important;
}

.app-table {
  .app-table-remove {
    transition: opacity 0.5s;
    will-change: opacity;

    cursor: pointer;
    opacity: 0;
  }

  tr {
    &:hover {
      .app-table-remove {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

.next,
.reverse {
  &:hover {
    &:not([disabled]) {
      svg {
        transform: translateX(0px);

        path {
          opacity: 1;
        }
      }
    }
  }

  &:not([disabled]) {
    svg {
      transform: translateX(-8px);
      transition: all ease 0.3s;

      path {
        fill: var(--white);
        opacity: 0.5;
        transition: all ease 0.3s;
      }
    }
  }
}

.reverse {
  &:hover {
    &:not([disabled]) {
      svg {
        transform: translateX(0px);
        rotate: 180deg;
      }
    }
  }

  &:not([disabled]) {
    svg {
      rotate: 180deg;
    }
  }
}

.main-title {
  text-align: left;
  font-family: var(--secondary-font);
  font-size: var(--font-xxl);
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
}

.section-title {
  text-align: left;
  font-family: var(--secondary-font);
  font-size: var(--font-lg);
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
}

.tab-title {
  text-align: left;
  font-family: var(--secondary-font);
  font-size: var(--font-md);
  letter-spacing: 0px;
  color: var(--black);
  opacity: 1;
}

.body-text {
  text-align: left;
  font-family: var(--main-font);
  font-size: var(--font-md);
  letter-spacing: 0px;
  color: var(--dark);
  opacity: 1;
}

.sub-text {
  text-align: left;
  font-family: var(--main-font);
  font-size: var(--font-xs);
  letter-spacing: 0px;
  color: var(--light);
  opacity: 1;
}

.flex-1 {
  flex: 1;
}

.basic-content {
  background-color: var(--white);

  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 992px) {
    padding: 16px !important;
  }

  &-title {
    display: block;
    text-align: left;
    font-size: var(--font-xxl);
    font-family: var(--secondary-font);
    letter-spacing: 0px;
    color: var(--black);
    opacity: 1;
  }

  &-subtitle {
    display: block;
    text-align: left;
    font-size: var(--font-lg);
    font-family: var(--secondary-font);
    letter-spacing: 0px;
    color: var(--black);
    opacity: 1;
  }

  &-description {
    display: block;
    text-align: left;
    font-size: var(--font-md);
    font-family: var(--main-font);

    letter-spacing: 0px;
    color: var(--dark);
    opacity: 1;
  }
}

.add-button {
  i {
    padding-left: 2px;
    margin-right: 18px;
    font-size: 20px;
  }
}

.app-radio-text {
  text-align: left;
  font-family: var(--main-font);
  font-size: var(--font-sm);

  letter-spacing: 0px;
  color: var(--dark);
  opacity: 1;
}

.table-subtitle {
  display: block;
  text-align: left;
  font-size: var(--font-xs);

  font-family: var(--main-font);
  color: var(--light);
  opacity: 1;
  margin: 0;
}

.app-table-body-row-subtitle {
  display: block;
  text-align: left;
  font-size: var(--font-xs);
  font-family: var(--main-font);
  color: var(--light);
}

.table-sum {
  &-title {
    text-align: left;
    font-size: var(--font-md);
    font-family: var(--secondary-font);

    color: #2e008b;
  }

  &-amount {
    text-align: left;
    font-size: var(--font-md);
    font-family: var(--main-font);

    color: var(--dark);
  }
}

.declarations-description {
  font-size: var(--font-sm);
  color: var(--black);
}

.sidebar-logo {
  width: 100%;
  max-width: 184px;
  object-fit: contain;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: inherit !important;
  padding: 10px 16px;
}

@media (min-width: 992px) {
  .signup-basic-page {
    min-height: 100vh;
  }

  .basic-sidebar-body {
    padding: 48px 61px;
  }
}

.basic-sidebar-body {
  justify-content: space-between;
  min-height: calc(100vh - 89px);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styled-scroll {
  width: 100%;
  overflow: hidden;

  &:hover {
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      transition: all 0.5s ease-in-out;
      background: rgba(213, 204, 321, 0.3);
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: 0px none var(--white);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(213, 204, 321, 0.6);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(213, 204, 321, 0.6);
  }
  &::-webkit-scrollbar-track {
    background: var(--white);
    border: 0px none var(--white);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: var(--white);
  }
  &::-webkit-scrollbar-track:active {
    background: var(--white);
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &-card {
    padding: 30px;
    background-color: var(--white);
    box-shadow: 0px 3px 4px var(--transparent-black);
    border-radius: 8px;
    width: 100%;

    &-title {
      text-align: left;
      font-size: var(--font-lg);
      font-family: var(--main-font);

      color: var(--dark);
    }

    &.no-padding {
      padding: 0;
    }

    .padding-container {
      padding: 30px;
    }
  }

  &-item {
    &-buttons {
      display: flex;
      flex-direction: row;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 24px;
      height: 24px;

      border: none;
      background: none;

      i {
        font-size: 16px;
        color: #a6a6a6;

        transition: color 0.3s ease-in-out;
        will-change: color;
      }

      &:hover {
        i {
          color: var(--main);
        }
      }
    }
  }

  table {
    width: 100%;

    &.bordered {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid #e5e5e5;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: var(--main-alternative-lighter);
        }

        td {
          button,
          a {
            transition: opacity 0.3s ease-in-out;
            will-change: opacity;
            color: var(--black);

            opacity: 0;
          }
        }

        &:hover {
          td {
            button,
            a {
              opacity: 1;
            }
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          &:first-child {
            font-size: var(--font-xs);
            font-family: var(--main-font);
            color: var(--light);
          }

          &:last-child {
            font-size: var(--font-sm);
            font-family: var(--main-font);
            font-weight: bold;
            color: var(--black);
          }
        }
      }
    }

    th {
      font-family: var(--main-font);
      font-size: var(--font-xxs);

      letter-spacing: 0;
      color: var(--gray);
      text-align: left;
      text-transform: uppercase;

      // text-transform: uppercase;

      padding: 0 16px;
      padding-left: 0;
    }

    td {
      padding: 16px;
      padding-left: 0;

      &:first-child {
        padding-left: 16px;
      }
    }
  }
}

.side-modal {
  &-form {
    &-title {
      font-family: var(--secondary-font);
      font-size: var(--font-xxl);
      font-weight: normal;

      color: var(--main);
    }

    &-description {
      font-family: var(--main-font);
      font-size: var(--font-md);
      font-weight: normal;

      color: var(--dark);
    }
  }
}

.warning-modal {
  h3 {
    font-size: var(--font-xl);
    font-weight: 300;
    font-family: var(--font-primary);
    color: var(--main);
  }

  h2 {
    font-size: var(--font-xxl);
    font-weight: 300;
    font-family: var(--font-primary);
    color: var(--main);
  }

  a {
    color: var(--black);
    font-size: var(--font-sm);

    &:hover {
      text-decoration: none;
    }
  }

  p {
    font-size: var(--font-sm);
    color: var(--black);
  }

  button {
    width: 100%;
  }
}

.pagination {
  &-indicator {
    font-family: var(--main-font);
    font-size: var(--font-sm);
    font-weight: normal;

    color: var(--black);
  }

  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin-left: -2px;
    margin-right: -2px;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border: 1px solid var(--lighter-alternative-purple);
    background-color: var(--white);
    color: var(--dark);
    border-radius: 8px;

    margin-left: 2px;
    margin-right: 2px;

    transition: all 0.3s ease-in-out;

    &.prev,
    &.next {
      width: 90px;
    }

    &.active {
      background-color: var(--secondary);
      border: 1px solid var(--secondary);

      color: var(--white);
    }
  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.invisible-button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background: none;
}

.account {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &-card {
    padding: 30px;
    background-color: var(--white);
    box-shadow: 0px 3px 4px var(--transparent-black);
    border-radius: 8px;
    width: 100%;
    max-width: 675px;
  }
}

.ng-dropdown-panel .scroll-host {
  &::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--main-lighter);
    border-radius: 50px;
    border: 2px solid white;
    transition: all 0.3s ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--main-alternative-light);
  }
  &::-webkit-scrollbar-thumb:active {
    background: var(--main-alternative-light);
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border: 0px none var(--main-lighter);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-track:active {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.basic-sidebar-footer-button-text {
  margin-right: 0 !important;
}

.basic-sidebar-footer-content button {
  width: 195px;
  display: flex;
  transition: all ease 0.2s;

  &.loading {
    width: fit-content;
    padding: 10px 16px !important;
  }
}

.basic-siderbar-footer-button,
.basic-sidebar-footer-content button .app-button-label {
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.terms-container {
  min-height: 100%;
}

.dashboard {
  width: 100%;

  &-card {
    width: 100%;

    background-color: var(--white);
    box-shadow: 0px 3px 4px var(--transparent-black);
    border-radius: 8px;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 30px;

      span {
        font-family: var(--secondary-font);
        font-size: var(--font-lg);
        letter-spacing: 0;
        color: var(--black);
        font-weight: bold;
      }

      button {
        width: 24px;
        height: 24px;

        background: transparent;
        border: none;
        outline: none;

        i {
          color: var(--light);
          font-size: 16px;
        }
      }
    }

    &-scroll {
      width: 100%;
      overflow: auto;
    }

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      padding: 30px;
    }

    &-body {
      table {
        width: 100%;

        tbody {
          tr {
            &:hover {
              background-color: var(--main-alternative-lighter);
            }

            &:hover {
              .item-number-options {
                background-color: var(--main-alternative-lighter);
                width: 100%;
              }

              .item-number {
                color: var(--white);
              }
            }
          }
        }
        th {
          font-family: var(--main-font);
          font-size: var(--font-xxs);
          font-weight: bold;
          letter-spacing: 0;
          color: var(--gray);
          text-align: left;

          text-transform: uppercase;

          padding: 0 30px;
        }

        td {
          padding: 0 30px;
          .status {
            font-family: var(--main-font);
            font-size: var(--font-sm);
            font-weight: normal;
            letter-spacing: 0;
            color: var(--dark);
            text-align: left;
          }

          .progress {
            &-container {
              display: flex;
              flex-direction: column;
            }

            &-percentage {
              font-family: var(--main-font);
              font-size: var(--font-sm);
              font-weight: bold;
              letter-spacing: 0;
              color: var(--dark);
              text-transform: capitalize;
              text-align: left;
            }

            &-bar {
              position: relative;

              width: 100%;
              height: 9px;
              border-radius: 100px;

              background-color: var(--secondary-lighter);

              &-complete {
                position: absolute;
                top: 0;
                left: 0;

                height: 9px;
                border-radius: 100px;

                background-color: var(--secondary);
              }
            }
          }

          .button {
            width: 24px;
            height: 24px;
            padding: 0;

            border: none;
            outline: none;
            background: none;

            i {
              font-size: 15px;
              color: var(--light);
            }

            &:hover {
              i {
                color: var(--main);
              }
            }
          }
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      padding: 16px 0;

      cursor: pointer;

      &-initials {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        min-width: 48px;
        border-radius: 50%;
        background-color: var(--secondary-lighter);

        span {
          font-family: var(--main-font);
          font-size: var(--font-xl);
          font-weight: bold;
          letter-spacing: 0;
          color: var(--main);
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;

        flex: 1;

        &-title {
          font-family: var(--main-font);
          font-size: var(--font-sm);
          font-weight: bold;
          letter-spacing: 0;
          color: var(--main);
        }

        &-subtitle {
          font-family: var(--main-font);
          font-size: var(--font-xs);
          font-weight: normal;
          letter-spacing: 0;
          color: var(--light);
        }
      }
    }

    &-statistics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      padding-top: 2px;
      padding-bottom: 24px;
      padding-left: 30px;
      padding-right: 30px;

      margin-right: -16px;
      margin-left: -16px;

      &-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: wrap;

        background-color: var(--light-gray);
        border-radius: 8px;
        padding: 12px 16px;

        margin-right: 16px;
        margin-left: 16px;
        margin-bottom: 16px;

        min-width: 100px;
      }

      &-title {
        font-family: var(--main-font);
        font-size: var(--font-sm);
        font-weight: bold;
        letter-spacing: 0;
        color: var(--main-light);
      }

      &-value {
        font-family: var(--secondary-font);
        font-size: var(--font-xl);
        font-weight: normal;
        letter-spacing: 0;
        color: var(--main);
      }
    }
  }
}

.item-number {
  text-align: right;
  position: relative;

  &-options {
    position: absolute;
    right: 0;
    background-color: transparent;
    top: 0;
    padding: 0px 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 0;
      background-color: transparent;
      border: none;
      color: var(--alternative-black);

      &:hover {
        color: var(--main);
      }
    }
  }
}

.item-actions {
  position: relative;

  &-container {
    position: absolute;
    right: 0;
    background-color: transparent;
    top: 0;
    padding: 0px 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: var(--main-alternative-lighter);

    &-options {
      color: var(--alternative-black);
      margin-left: 19px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: var(--main);
      }
    }
  }
}

.app-table-details-body {
  &:hover {
    .item-actions-container {
      opacity: 1;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.dash {
  width: 100%;
  &-card {
    position: relative;
    width: 100%;

    background-color: var(--white);
    box-shadow: 0px 3px 4px var(--transparent-black);
    border-radius: 8px;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 30px 30px 25px;

      h2 {
        margin: 0;
        color: var(--black);
        font-size: 21px;
        font-weight: 600;
        font-family: var(--secondary-font);
      }

      span {
        font-family: var(--secondary-font);
        font-size: var(--font-lg);
        letter-spacing: 0;
        color: var(--alternative-black);
      }

      button {
        width: 24px;
        height: 24px;

        background: transparent;
        border: none;
        outline: none;

        i {
          color: var(--light);
          font-size: 16px;
        }
      }

      .add-button {
        button {
          width: auto;
          height: auto;

          i {
            color: var(--white);
          }
        }

        &.reverse {
          i {
            color: var(--main);
          }
        }
      }
    }

    &-scroll {
      width: 100%;
      overflow: auto;
    }

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      padding: 30px;
    }

    &-body {
      table {
        width: 100%;

        tbody {
          tr {
            border-bottom: 1px solid var(--lighter);

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: var(--main-alternative-lighter);
            }
          }
        }
        th {
          font-family: var(--main-font);
          font-size: var(--font-xxs);
          font-weight: bold;
          letter-spacing: 0;
          color: #bfbfbf;
          text-align: left;

          text-transform: uppercase;

          padding: 0 30px;
        }

        tr.alternative-thead {
          th {
            font-family: var(--secondary-font);
            font-size: var(--font-md);
            color: var(--alternative-black);
            padding: 12px 30px;
            text-transform: none;
          }
        }

        td {
          padding: 0 30px;
          .status {
            font-family: var(--main-font);
            font-size: var(--font-sm);
            font-weight: normal;
            letter-spacing: 0;
            color: var(--gray-dark);
            text-align: left;

            &-aprovation {
              font-size: var(--font-xs);
              font-family: var(--main-font);
              color: var(--light);
            }
          }

          .progress {
            &-container {
              display: flex;
              flex-direction: column;
            }

            &-percentage {
              font-family: var(--main-font);
              font-size: var(--font-sm);
              font-weight: bold;
              letter-spacing: 0;
              color: var(--dark);
              text-transform: capitalize;
              text-align: left;
            }

            &-bar {
              position: relative;

              width: 100%;
              height: 9px;
              border-radius: 100px;

              background-color: var(--secondary-lighter);

              &-complete {
                position: absolute;
                top: 0;
                left: 0;

                height: 9px;
                border-radius: 100px;

                background-color: var(--secondary);
              }
            }
          }

          .button {
            width: 24px;
            height: 24px;
            padding: 0;

            border: none;
            outline: none;
            background: none;

            i {
              font-size: 15px;
              color: var(--black);
            }

            &:hover {
              i {
                color: var(--main);
              }
            }
          }
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 16px 0;

      &-initials {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        min-width: 48px;

        border-radius: 50%;
        background-color: var(--secondary-lighter);

        &.user-credentials {
          width: 32px;
          min-width: 32px;
          height: 32px;
          margin-right: 16px;

          span {
            font-size: var(--font-xs);
          }
        }

        span {
          font-family: var(--main-font);
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          color: var(--secondary);
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;

        flex: 1;

        &-title {
          font-family: var(--main-font);
          margin-bottom: 2px;
          font-size: var(--font-sm);
          letter-spacing: 0;
          font-weight: 500;
          color: var(--black);
          word-break: initial;
        }

        &-subtitle {
          font-family: var(--main-font);
          font-size: var(--font-xs);
          font-weight: normal;
          letter-spacing: 0;
          color: var(--light);
        }
      }

      &-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
          padding: 0;
          margin: 0;
          border: none;
          background-color: transparent;
        }
      }
    }

    &-statistics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      padding-top: 2px;
      padding-bottom: 24px;
      padding-left: 30px;
      padding-right: 30px;

      &-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: wrap;

        background-color: var(--main-alternative-lighter);
        border-radius: 8px;
        padding: 14px 24px 17px;

        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-title {
        font-family: var(--main-font);
        font-size: var(--font-xs);
        letter-spacing: 0;
        color: var(--alternative-gray);
      }

      &-value {
        font-family: var(--secondary-font);
        font-size: var(--font-xl);
        font-weight: normal;
        letter-spacing: 0;
        color: var(--black);
      }
    }

    &-pagination {
      padding: 30px;
      padding-top: 0;
    }
  }
}

.fund-dash {
  .dash-card-header {
    padding-bottom: 32px;

    span {
      color: var(--black);
      font-size: 21px;
      font-weight: 600;
    }
  }

  .dash-card-body {
    table {
      th {
        color: var(--alternative-black);
        font-size: var(--font-md);
        text-transform: capitalize;
        font-family: var(--secondary-font);
        padding: 10px 30px !important;
      }

      td:first-child {
        padding-left: 30px;
      }
    }
  }

  &-header-buttons {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-button i,
    .add-button svg {
      margin-right: 12px;
      font-size: 17px;
    }
  }
}

.dash-title-container {
  padding: 30px 30px 43px !important;
  font-size: 21px;
  font-weight: 600;
}

.dash-details {
  width: 100%;

  &-card {
    width: 100%;

    background-color: var(--white);
    box-shadow: 0px 3px 4px var(--transparent-black);
    border-radius: 8px;

    &-header {
      padding: 30px 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-family: var(--secondary-font);
        font-size: var(--font-lg);
        letter-spacing: 0;
        color: var(--alternative-black);
      }
    }

    &-body {
      padding: 30px;
      padding-top: 0;
    }

    &-item {
      &-title {
        font-family: var(--main-font);
        margin-bottom: 2px;
        font-size: var(--font-sm);
        letter-spacing: 0;
        font-weight: 500;
        color: var(--alternative-dark);

        a {
          color: var(--alternative-dark);
          text-decoration: none;
        }
      }

      &-subtitle {
        font-family: var(--main-font);
        font-size: var(--font-xs);
        font-weight: normal;
        letter-spacing: 0;
        color: var(--light);
        text-transform: uppercase;
        word-break: break-word;

        a {
          color: var(--light);
          text-decoration: none;
        }
      }

      &-badged {
        font-family: var(--main-font);
        color: var(--white);
        font-size: var(--font-xs);
        background-color: var(--alternative-secondary-light);
        border-radius: 4px;
        font-weight: bold;
        width: fit-content;
        padding: 4px 14px;

        &-list {
          font-family: var(--main-font);
          color: var(--white);
          font-size: var(--font-xs);
          background-color: var(--alternative-secondary-light);
          border-radius: 4px;
          font-weight: bold;
          width: fit-content;
          padding: 2px 14px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.card-info {
  &-number {
    font-family: var(--secondary-font);
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;

    color: var(--black);
  }

  &-title {
    font-family: var(--secondary-font);
    font-size: var(--font-lg);
    font-weight: normal;
    color: var(--alternative-gray);
  }

  &-form {
    &-title {
      font-family: var(--secondary-font);
      font-size: var(--font-xxl);
      font-weight: normal;

      color: var(--main);
    }

    &-description {
      font-family: var(--main-font);
      font-size: var(--font-md);
      font-weight: normal;

      color: var(--dark);
    }
  }
}

.app-table-details {
  width: 100%;
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;

  &-header {
    padding: 12px 10px;
    font-size: var(--font-md);
    color: var(--alternative-black);
    font-weight: 600;
    font-family: var(--secondary-font);

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }

  &-body {
    display: grid;
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--lighter);
    }

    &:hover {
      background: var(--main-alternative-lighter);

      .icon-container {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &-row {
      padding: 12px 10px;
      font-size: var(--font-sm);
      color: var(--alternative-dark);
      font-family: var(--main-font);
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-wrap: balance;
      word-break: break-word;

      &-subtitle {
        display: block;
        text-align: left;
        font-size: var(--font-xs);
        font-family: var(--main-font);
        color: var(--light);
      }

      &-badged {
        font-family: var(--main-font);
        color: var(--white);
        font-size: var(--font-xs);
        background-color: var(--alternative-secondary-light);
        border-radius: 4px;
        font-weight: bold;
        width: fit-content;
        margin-left: auto;
        padding: 4px 14px;
      }

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }

      img {
        margin-right: 16px;
      }

      i {
        font-size: 18px;
      }

      .icon-container {
        opacity: 0;
        transition: all ease 0.3s;
        will-change: opacity;
        pointer-events: none;
        margin-left: auto;
        cursor: pointer;

        a {
          color: var(--black);
        }

        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        i {
          font-size: 18px;

          &:first-child {
            margin-right: 18px;
          }
        }
      }

      &-initials {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        min-width: 48px;

        height: 48px;
        border-radius: 50%;
        background-color: var(--secondary-lighter);
        font-family: var(--main-font);
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        color: var(--secondary);
        margin-right: 16px;
      }
    }
  }
}

.table-details-container {
  padding-bottom: 30px;
}

.modal-text-title {
  color: var(--black);

  h3 {
    font-family: var(--secondary-font);
    font-size: var(--font-md);
    font-weight: 600;
    margin-bottom: 15px;
  }

  p {
    font-size: var(--font-sm);
    font-family: var(--main-font);
    margin-bottom: 15px;
  }
}

.card-padding-top {
  padding-top: 30px;
}

.card-padding-bottom {
  padding-bottom: 24px;
}

.basic-info {
  h3 {
    margin-bottom: 5px;
    font-family: var(--main-font);
    font-size: var(--font-xs);
    color: var(--alternative-gray);
    font-weight: 500;
  }

  h4 {
    margin: 0;
    font-family: var(--secondary-font);
    font-size: var(--font-lg);
    color: var(--black);
    font-weight: 600;
  }
}

.dropdown-button {
  background: transparent;
  border: 0;
  padding: 8px 15px;
  width: 100% !important;
  height: auto !important;
  text-align: left;
  display: block;
  font-size: var(--font-sm);
  font-family: var(--main-font);
  color: var(--input-black);
  transition: all ease 0.3s;

  &.outline {
    padding: 4px 16px;
    color: var(--dark);

    &:hover {
      background-color: #f2f9fd;
    }
  }
}

.dropdown-ul {
  margin: 0 0 2em 0;
  list-style-type: none;
  padding: 0;
  width: 15em;
}
.dropdown-ul li {
  display: flex;
}

.dropdown-ul button {
  flex: 1;
  cursor: pointer;
  position: relative;
  left: 0;
  margin: 0.5em;
  padding: 0;
  background: transparent;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  height: 1.8em;
  font-size: var(--font-sm);
  font-family: var(--main-font);
  color: var(--input-black);
}

.dash-pagination-alternative {
  padding-top: 30px;
  justify-content: space-between;
  flex-direction: row-reverse !important;
  align-items: center !important;
  display: flex;

  .pagination-indicator {
    color: var(--dark);

    b {
      font-weight: 500;
    }
  }

  .pagination-button {
    background-color: transparent;
    border: 1px solid transparent;
    font-size: var(--font-sm);
    font-family: var(--main-font);

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      color: var(--primary);
      background-color: var(--white);
    }

    &:hover {
      color: var(--black);
      border: 1px solid var(--main-lighter);
    }
  }
}

.switch.switch-small {
  height: 22px;
  min-width: 44px;
  border-radius: 100px;
  background-color: #e5e5e5;

  &:focus-within {
    outline: none;
  }
}

.switch {
  border: none;
}

.switch small {
  top: 2px;
  background: #808080;
  box-shadow: none;
}

.switch.checked small {
  right: 2px;
  background-color: #fa7e40;
}

.switch.switch-small small {
  width: 18px;
  height: 18px;
}

.switch.checked {
  background-color: #fee5d8 !important;
}

.switch-label {
  color: var(--dark);
  font-size: var(--font-sm);
  font-family: var(--main-font);
}

.fund-edit-name {
  color: var(--black);
  font-size: var(--font-lg);
  font-family: var(--font-primary);
  margin-bottom: 24px;
}

.hasError {
  font-size: 27px;
  font-weight: 900;
  margin-left: 7px;
  margin-bottom: -5px;
  position: absolute;
  font-family: var(--secondary-font);
  color: red;
  left: -7px;
}

.app-dropzone-preview {
  position: relative;
  width: 100% !important;
  height: 100px !important;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: -1px 1px 20px 0px rgb(0 0 0 / 12%);

  // Background gradient
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );

  object-fit: cover;
  z-index: 1000;
}

.modal-demo .ui-modal {
  width: 37.5rem;
  /* for resize limits use min-width, min-height, max-width, max-height in css */
}
.modal-demo .ui-modal-overlay,
.modal-demo .ui-modal {
  z-index: 10;
}
/* colors */
:root {
  --dt-color-primary: #5b9bd5;
}
/* for IE */
.ui-modal-header,
.ui-modal-body,
.ui-modal-footer {
  background-color: #eeeeee !important;
}

.ui-modal-footer {
  text-align: center;
  font-family: var(--main-font);
  color: var(--black);
}

.attachment-modal-navigation {
  display: flex;
  align-items: center;
  justify-content: center;

  &-arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--main-alternative-lighter);
    border: 1px solid var(--main);
    color: var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      background-color: var(--main);
      color: var(--white);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.skeleton-table .dash-card-item-initials {
  background-color: transparent;
}

.ui-titlebar {
  justify-content: space-between;
  color: #212121;
  font-size: 16px;
}

.ui-icon.dt-icon-close {
  color: #212121;
  font-size: 16px;
}

.custom-tooltip .tooltip-inner {
  background-color: var(--main);
  padding: 2px 10px;
  border-radius: 20px;
}

.custom-tooltip .arrow::before {
  border-top-color: transparent;
}

.user-settings-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.user-settings-modal {
  width: 600px;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 12px;
  overflow: auto;
  max-height: 99vh;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  &-header {
    padding: 32px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: var(--main);
      font-size: var(--font-xxl);
      font-weight: 300;
    }

    span {
      color: var(--alternative-gray);
      font-size: 40px;
      transform: rotate(45deg);
      font-weight: 300;
      cursor: pointer;
    }
  }

  svg {
    margin: 16px auto 32px;
    width: 290px;
    display: block;
  }

  &-content {
    padding: 0 32px;
  }

  &-footer {
    padding: 32px;
    display: flex;
    justify-content: space-between;
  }
}

.user-settings-modal-description-text {
  text-align: left;
  font-family: var(--main-font);
  font-size: var(--font-xs);

  letter-spacing: 0px;
  color: var(--light);
  opacity: 1;

  margin: 0;
}

.user-settings-modal-password-meter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &-text {
    text-align: left;
    font-size: var(--font-xs);
    font-family: var(--main-font);

    letter-spacing: 0px;
    color: var(--light);

    opacity: 1;

    margin: 0;
    margin-right: 10px;
  }

  &-bars {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.strong {
      color: purple;
      .user-settings-modal-password-meter-bar {
        background-color: #38cdff;
      }
    }
  }

  &-bar {
    display: flex;

    border-radius: 100px;
    background-color: var(--light);

    flex: 1;
    max-width: 36px;
    height: 4px;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &.weak {
      background-color: #e80606;
    }

    &.middle {
      background-color: #ff7610;
    }
  }
}

.swal2-container {
  z-index: 99999 !important;
}

.app-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.file-icon-img {
  height: 30px;
}
